* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

mark{
  background-color: #e0ecc1;
  padding: 0.1em;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}

.gm-style .gm-style-iw-c {
  padding-right: 12px !important;
  padding-bottom: 12px !important;
}

.bubble-container{
  margin-top: 8px;
  margin-bottom: 8px;
  display:flex;
  font-family: sans-serif;
  font-size: 14px;
  align-items: center;
}

.bubble-direction-reverse {
  flex-direction: row-reverse;
}

.bubble{
    background-color: #F2F2F2;
    border-radius: 5px;
    box-shadow: 0 0 6px #B2B2B2;
    display: block;
    padding: 10px 26px 10px 17px;
    position: relative;
    vertical-align: top;
    color: white;
    word-wrap: break-word;
}

.bubble::before {
    background-color: #F2F2F2;
    content: "\00a0";
    display: block;
    height: 16px;
    position: absolute;
    top: 11px;
    transform:             rotate( 29deg ) skew( -35deg );
        -moz-transform:    rotate( 29deg ) skew( -35deg );
        -ms-transform:     rotate( 29deg ) skew( -35deg );
        -o-transform:      rotate( 29deg ) skew( -35deg );
        -webkit-transform: rotate( 29deg ) skew( -35deg );
    width:  20px;
}

.bubble-check{
  float:right;
  position: absolute;
  bottom: 0;
  right: 0.2em;
}

.me {
    background-color: #8f5db7;
    margin-left: 18px;
    margin-right:60px;
}

.me::before {
    box-shadow: -2px 2px 2px 0 rgba( 178, 178, 178, .4 );
    left: -9px;
    background-color: #8f5db7;
}

.you {
    background-color: #087FFE;
    margin-left: 60px;
    margin-right:18px;
}

.you::before {
    box-shadow: 2px -2px 2px 0 rgba( 178, 178, 178, .4 );
    right: -9px;
    background-color: #087FFE;
}

.img-circle {
  border-radius: 42%;
  height:42px;
  width:42px;
}

.chats {
  position: relative;
  height: 100%;
}

.chat-list {
  height: calc(100% - 3rem);
  overflow-y: scroll;
  padding: 1rem;
  padding-bottom: 0.5rem;
}

.new-message {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #087FFE;
  height: 3rem;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-message-input {
  width: 75%;
  height: 80%;
  font-size: 1.0rem;
}
